import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import TransparentBackground from '../TransparentBackground'

const Modal = ({ show, onClose, children, modalId }) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const handleCloseClick = (e) => {
    e.preventDefault()
    onClose()
  }

  const modalContent = (
    <div>
      <TransparentBackground onClick={handleCloseClick} show={show} />
      <StyledModal
        className={`absolute top-0 w-[21rem] translate-x-[calc(50vw_-_10.5rem)] border bg-gray-100 duration-300 ease-in-out dark:border-gray-700 dark:bg-gray-900 sm:translate-x-[calc(50vw_-_10.5rem)] lg:w-1/3 lg:translate-x-[calc(50vw_-_100vw*1/6)] xl:w-1/4 xl:translate-x-[calc(50vw_-_100vw*1/8)] ${
          show ? 'translate-y-[calc(20vh)] opacity-100' : '-translate-y-full opacity-0'
        }`}
      >
        {children}
      </StyledModal>
    </div>
  )

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById(modalId))
  } else {
    return null
  }
}

const StyledModal = styled.div`
  height: 400px;
  padding: 15px;
`

export default Modal
