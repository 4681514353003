import Modal from '@/components/modals/Modal'

export default function StatsModal({ setRankingModal, showRankingModal, totalStats }) {
  const statsView = () => {
    const initialValue = 0
    const sumWithInitial = totalStats.rounds.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    )
    if (sumWithInitial > 0) {
      return totalStats.rounds.map((item, index) => {
        const width = (item / totalStats.won) * 100 || 0
        return (
          <div className="mx-3 flex gap-3 xl:mx-7" key={index}>
            <span>{index + 1}:</span>
            <div
              className="h-full w-full bg-gray-400 text-right dark:bg-gray-700"
              style={{ width: `${width}%` }}
            >
              <span className="p-3">{item > 0 && item}</span>
            </div>
          </div>
        )
      })
    } else {
      return <div className="mt-5 text-center font-semibold">No win data availabe.</div>
    }
  }

  return (
    <Modal onClose={() => setRankingModal(false)} show={showRankingModal} modalId="modal-root">
      <div className="flex h-full flex-col">
        <div className="flex justify-center">
          <div className="m-3 flex-1 pl-7">
            <h2 className="text-center font-semibold text-gray-600 dark:text-gray-400">STATS</h2>
          </div>
          <div className="flex justify-self-end">
            <button
              aria-label="Close stats modal"
              onClick={() => {
                setRankingModal(false)
              }}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="gray"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>

        <div className="flex h-full flex-col gap-3 pt-2">
          <div className="mx-2 flex justify-center gap-10 text-sm">
            <div className="flex flex-col gap-2 ">
              <span>Played</span>
              <span className="text-center text-xl">{totalStats.played}</span>
            </div>
            <div className="flex flex-col gap-2 ">
              <span>Avg. Round Win</span>
              <span className="text-center text-xl">{totalStats.avgRoundWin}</span>
            </div>
            <div className="flex flex-col gap-2 ">
              <span>Won</span>
              <span className="text-center text-xl">{totalStats.won}</span>
            </div>
          </div>

          {statsView()}
        </div>
      </div>
    </Modal>
  )
}
